var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-7 col-xs-12 col-md-7"},[_c('v-sheet',{staticClass:"dense-inputs"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-row float-right",attrs:{"cols":"12","lg":"12","md":"12","xs":"12"}},[_c('v-text-field',{staticClass:"mt-2 mb-0 force-text-left",attrs:{"label":_vm.$t('message.filterResults'),"value":_vm.searchTerm,"autocomplete":"off","prepend-inner-icon":"mdi-filter-outline"},on:{"change":function($event){_vm.searchTerm = $event}}}),_c('v-btn',{staticClass:"ml-3",attrs:{"loading":_vm.loading.filterResults},on:{"click":_vm.filterResults}},[_vm._v(_vm._s(_vm.$t('message.filter')))]),_c('v-btn',{staticClass:"ml-3",on:{"click":function($event){_vm.searchTerm = null}}},[_c('v-icon',[_vm._v("refresh")])],1),_c('ExportTable',{staticClass:"mt-0 mr-0",staticStyle:{"padding-top":"2px !important"},attrs:{"has-add-function":true,"cols-to-delete":_vm.colsToDelete,"file-name":"product-groups","table-id":"productGroupsTable"},on:{"add-action":function($event){return _vm.openCreateProductGroupDialog()}}}),_c('HelpPopUpV2',{attrs:{"help-page-component":"ProductGroupsListing","no-padding":true}})],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading.productGroups,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-0 appic-table-light specification-table",attrs:{"footer-props":{
            itemsPerPageOptions: [20,50,-1],
            showCurrentPage: true,
            showFirstLastPage: true
        },"headers":_vm.headers,"height":_vm.tableHeight,"items":_vm.allProductGroups,"items-per-page":-1,"search":_vm.searchTerm,"options":_vm.tableOptions,"sort-by":['ProductGroup.title'],"calculate-widths":"","dense":"","fixed-header":"","id":"productGroupsTable","item-key":"ProductGroup.id"},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.allProductGroups.indexOf(item) + 1))])]}},{key:"item.ProductGroup.title",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold text-no-wrap"},[_vm._v(_vm._s(item.ProductGroup.title))])]}},{key:"item.ProductGroup.fsc_product_name",fn:function(ref){
        var item = ref.item;
return [(item.ProductGroup.fsc_product_name != null)?_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.ProductGroup.fsc_product_name))]):_c('span',[_vm._v(_vm._s(_vm.$t('message.na')))])]}},{key:"item.ProductGroup.pefc_product_name",fn:function(ref){
        var item = ref.item;
return [(item.ProductGroup.pefc_product_name != null)?_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.ProductGroup.pefc_product_name))]):_c('span',[_vm._v(_vm._s(_vm.$t('message.na')))])]}},{key:"item.ProductGroup.id",fn:function(ref){
        var item = ref.item;
return [(item.ProductGroup.id != 999)?_c('div',{staticClass:"text-center"},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.moreActions')))])])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.openEditProductGroupDialog(item.ProductGroup.id, item.ProductGroup.title)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"black lighten-1","small":""}},[_vm._v("edit")]),_vm._v(_vm._s(_vm.$t('message.updateProductGroup'))+" ")],1),_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.deleteProductGroup(item.ProductGroup.id, item.ProductGroup.title)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"red lighten-1"}},[_vm._v("cancel")]),_vm._v(_vm._s(_vm.$t('message.deleteProductGroup')))],1)],1)],1)],1):_c('div',{staticClass:"text-center"},[_c('v-icon',{attrs:{"small":"","color":"red darken-1"}},[_vm._v("lock")])],1)]}}])}),_c('AddProductGroup',{attrs:{"product-group-id":_vm.selectedProductGroupId,"product-group-name":_vm.selectedProductGroupName,"dialog":_vm.editDialog,"update-mode":_vm.updateMode},on:{"update:dialog":function($event){_vm.editDialog=$event},"dialog-closed":_vm.editDialogClosed,"update-done":_vm.productGroupUpdated,"create-done":_vm.productGroupCreated}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }