<template>
    <div class="col-7 col-xs-12 col-md-7">
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row float-right">
                    <v-text-field
                        :label="$t('message.filterResults')"
                        :value="searchTerm"
                        autocomplete="off"
                        class="mt-2 mb-0 force-text-left"
                        prepend-inner-icon="mdi-filter-outline"
                        @change="searchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="searchTerm = null"><v-icon>refresh</v-icon></v-btn>
                    <ExportTable
                        :has-add-function="true"
                        :cols-to-delete="colsToDelete"
                        class="mt-0 mr-0"
                        file-name="product-groups"
                        table-id="productGroupsTable"
                        style="padding-top: 2px !important;"
                        @add-action="openCreateProductGroupDialog()"
                    ></ExportTable>
                    <HelpPopUpV2 help-page-component="ProductGroupsListing" :no-padding="true"></HelpPopUpV2>
                </v-col>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.productGroups"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [20,50,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :height="tableHeight"
            :items="allProductGroups"
            :items-per-page="-1"
            :search="searchTerm"
            :options.sync="tableOptions"
            :sort-by="['ProductGroup.title']"
            calculate-widths
            class="mt-0 appic-table-light specification-table"
            dense
            fixed-header
            id="productGroupsTable"
            item-key="ProductGroup.id"
        >
            <template v-slot:item.index="{ item }">
                <div class="text-center">{{ allProductGroups.indexOf(item) + 1}}</div>
            </template>
            <template v-slot:item.ProductGroup.title="{ item }">
                <span class="font-weight-bold text-no-wrap">{{ item.ProductGroup.title }}</span>
            </template>
            <template v-slot:item.ProductGroup.fsc_product_name="{ item }">
                <span class="text-no-wrap" v-if="item.ProductGroup.fsc_product_name != null">{{ item.ProductGroup.fsc_product_name }}</span>
                <span v-else>{{ $t('message.na') }}</span>
            </template>
            <template v-slot:item.ProductGroup.pefc_product_name="{ item }">
                <span class="text-no-wrap" v-if="item.ProductGroup.pefc_product_name != null">{{ item.ProductGroup.pefc_product_name }}</span>
                <span v-else>{{ $t('message.na') }}</span>
            </template>
            <template v-slot:item.ProductGroup.id="{ item }">
                <div class="text-center" v-if="item.ProductGroup.id != 999">
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.moreActions') }}</span>
                            </v-tooltip>
                        </template>
                        <v-list dense>
                            <v-list-item class="font-sm" @click="openEditProductGroupDialog(item.ProductGroup.id, item.ProductGroup.title)">
                                <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateProductGroup') }}
                            </v-list-item>
                            <v-list-item class="font-sm" @click="deleteProductGroup(item.ProductGroup.id, item.ProductGroup.title)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteProductGroup') }}</v-list-item>
                        </v-list>
                    </v-menu>
                </div>
                <div class="text-center" v-else>
                    <v-icon small color="red darken-1">lock</v-icon>
                </div>
            </template>
        </v-data-table>
        <AddProductGroup
            :product-group-id="selectedProductGroupId"
            :product-group-name="selectedProductGroupName"
            :dialog.sync="editDialog"
            :update-mode="updateMode"
            @dialog-closed="editDialogClosed"
            @update-done="productGroupUpdated"
            @create-done="productGroupCreated"
        ></AddProductGroup>
    </div>
</template>

<script>
// import AddProductGroup from "Components/Appic/AddProductGroup";
import {mapActions, mapGetters} from "vuex";
import {isset, log} from "Helpers/helpers";
// import {mapFields} from "vuex-map-fields";

const AddProductGroup = () => import("Components/Appic/AddProductGroup");
const ExportTable = () => import("Components/Appic/ExportTable");
const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");

export default {
    name: "ProductGroupsListing",
    components: {AddProductGroup, ExportTable, HelpPopUpV2},
    props: ['addDialog','reload', 'idToEdit'],
    data() {
        return {
            colsToDelete: {
                start_col: 0,
                ncols: 1
            },
            dialogs: {
                error: false,
                error_message: ""
            },
            editDialog: null,
            filterOptions: {
                office: 1
            },
            hideDefaultFooter: true,
            loading: {
                fetch: false,
                filterResults: false,
                productGroups: false
            },
            searchField: null,
            searchTerm: null,
            selectedProductGroupId: null,
            selectedProductGroupName: null,
            tableHeight: '500',
            tableOptions: {
                page: 1
            },
            totalProductGroups: 0,
            updateMode: false
        }
    },
    computed: {
        ...mapGetters('productgroup', {
            allProductGroups: 'allProductGroups'
        }),
        headers () {
            return [
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'ProductGroup.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.product') + ' (' + this.$t('message.ncrAppic') + ')',
                    value: 'ProductGroup.title',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.productGroup') + ' (' + this.$t('message.zoho') + ')',
                    value: 'Parent.title',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.fscProduct'),
                    value: 'ProductGroup.fsc_product_name',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 5,
                    text: this.$t('message.pefcProduct'),
                    value: 'ProductGroup.pefc_product_name',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                }
            ]
        }
    },
    methods: {
        ...mapActions('productgroup',{
            deleteProductGroupById: 'deleteProductGroupById',
            getAllProductGroups: 'getAllProductGroups',
            resetState: 'resetState'
        }),
        async deleteProductGroup(val, title) {
            if(await this.$root.$confirm(this.$t('message.deleteProductGroup') + ' ' +  title, this.$t('message.confirmations.continueProductGroupCancelAction'), {color: 'orange'})){
                this.deleteProductGroupById(val).then((status) => {
                    if(status == 'done'){
                        this.$toast.success(this.$t('message.successes.productGroupDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.resetState()
                        this.getAllProductGroups()
                    } else {
                        this.$toast.error(this.$t('message.errors.productGroupNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                })
            }
        },
        editDialogClosed () {
            this.editDialog = false;
            if(this.updateMode == false){
                this.$emit('add-dialog-closed')
            }
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (285);
        },
        openConvertDialog () {
            this.convertDialog = true
        },
        openCreateProductGroupDialog () {
            this.updateMode = false
            this.editDialog = true
        },
        openEditProductGroupDialog (productGroupId, productGroupName) {
            this.selectedProductGroupId = productGroupId
            this.selectedProductGroupName = productGroupName
            this.updateMode = true
            this.editDialog = true
        },
        productGroupCreated () {
            this.editDialog = false;
            this.resetState()
            this.$emit('reload')
        },
        productGroupUpdated () {
            this.editDialog = false;
            this.resetState()
            this.$emit('reload')
        },
    },
    watch: {
        addDialog(value) {
            if(value) {
                this.openCreateProductGroupDialog ()
            }
        }
    },
    created() {
        if(this.allProductGroups.length == 0) this.getAllProductGroups()
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.handleResize()
        let _this = this
        setTimeout(function (){
            if(isset(() => _this.$route.query.panel) && _this.$route.query.panel == 'products'  && isset(() => _this.$route.query.id)){
                let id = _this.$route.query.id
                let product = _this.allProductGroups.find(p => p.ProductGroup.id == id)
                if(product) {
                    _this.openEditProductGroupDialog( id, product.ProductGroup.title)
                } else {
                    _this.$toast.error(_this.$t('message.errors.productGroupNotExist'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                }
            }
        },1000)
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style scoped>

</style>